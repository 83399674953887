import Link from "next/link";
import { footerMenuList, socialIcons } from "../data/footer_data";
import Image from "next/image";
import Logo from "./../public/logo/black.png";
// import WhiteLogo from "./../public/logo/white.png";
import WhiteLogo from "./../public/logo/colored_white.png";

const footer = () => {
  const date = new Date();
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className="dark:bg-jacarta-900 page-footer bg-white">
        <div className="container">
          <div className="grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12">
            <div className="col-span-12 md:col-span-4">
              {/* <!-- Logo --> */}
              <Link href="#" className="mb-6 inline-block">
                <Image
                  height={90}
                  width={190}
                  src={Logo}
                  className="max-h-7 dark:hidden"
                  alt="Impactoverse | Impact Ecosystem"
                />
              </Link>

              <Link href="#" className=" mb-6 inline-block">
                <Image
                  height={90}
                  width={190}
                  src={WhiteLogo}
                  className="hidden max-h-7 dark:block mb-6"
                  alt="Impactoverse | Impact Ecosystem"
                />
              </Link>
              <p className="dark:text-jacarta-300 mb-12">
                Shape a better future. Join a cause-driven community, back
                campaigns you care about, align with purposeful brands and
                influencers, and build an impact portfolio that benefits you and
                the planet.
              </p>

              {/* <!-- Socials --> */}
              <div className="flex space-x-5">
                {socialIcons.map((item) => {
                  const { id, href, text } = item;
                  return (
                    <Link
                      href={href}
                      key={id}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group cursor-pointer"
                    >
                      <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white">
                        <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                      </svg>
                    </Link>
                  );
                })}
              </div>
            </div>

            {footerMenuList.map((single) => (
              <div
                className={`col-span-6 sm:col-span-6 md:col-span-3 ${single.diffClass}`}
                key={single.id}
              >
                <h3 className="font-display text-jacarta-700 mb-6 text-lg dark:text-white">
                  {single.title}
                </h3>
                <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                  {single.list.map((item) => {
                    const { id, href, text } = item;
                    return (
                      <li key={id}>
                        <Link
                          target="_blank"
                          href={href}
                          className="hover:text-accent dark:hover:text-white"
                        >
                          {text}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>

          <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            {/* <span className="dark:text-jacarta-400 text-sm">
              <span>© {new Date().getFullYear()} Impactoverse — Made by</span>
              <Link
                href="/404"
                className="hover:text-accent dark:hover:text-white"
              >
                {" "}
                Noixtech
              </Link>
            </span> */}

            <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm mx-auto text-center">
              <li>
                <Link
                  href="/terms-of-service"
                  className="hover:text-accent dark:hover:text-white"
                >
                  Terms and Conditions
                </Link>
              </li>
              <li className="hidden md:block"> |</li>
              <li>
                <Link
                  href="/privacy-policy"
                  className="hover:text-accent dark:hover:text-white"
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="hidden md:block">
                {" "}
                | &nbsp;&nbsp;&nbsp;Copyright Impactoverse {date.getFullYear()}
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
